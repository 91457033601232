import React, { useEffect, useState, useRef } from 'react';

const ScrollCounter = ({ targetNumber, label }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          let start = 0;
          const increment = targetNumber / 100;
          const duration = 2000; // Duration in ms
          const interval = setInterval(() => {
            start += increment;
            if (start >= targetNumber) {
              clearInterval(interval);
              setCount(targetNumber);
            } else {
              setCount(Math.ceil(start));
            }
          }, duration / 100);

          setHasAnimated(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [targetNumber, hasAnimated]);

  return (
    <div ref={counterRef} style={{ textAlign: 'center', margin: '20px', color: '#fff' }}>
      <h1>{count}</h1>
      <p>{label}</p>
    </div>
  );
};

export default ScrollCounter;
