import React, { useState } from 'react';
import './AboutUs.css'; // Import your CSS file

const ImageGallery = () => {
  const [isHovered, setIsHovered] = useState(false);
  const images = [
    '1.jpg', '5.jpg', '4.jpg',
    '11.jpg', '7.jpg', '9.jpg',
    '12.jpg', '2.jpg', '3.jpg',
     '6.jpg', '8.jpg', '10.jpg'
  ];

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div >
      <div className="gallery-container">
        {images.map((image, index) => (
          <div 
            key={index} 
            className="gallery-item1"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <a href={image} target="_blank" rel="noopener noreferrer">
              <img 
                src={image} 
                alt={`Gallery Image ${index + 1}`}
                className={isHovered ? 'gallery-image1 hover' : 'gallery-image1'}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageGallery;