import React from 'react';
import './AboutUs.css'; 
import Header from './Header';
import Footer from './Footer';
import CarouselAutoPlay from './CarouselAutoPlay';

const AboutUs = () => {
 
  const galleryImages = [
    'image1.jpg', 'image2.jpg', 'image3.jpg',
    'image4.jpg', 'image5.jpg', 'image6.jpg',
    'image7.jpg', 'image8.jpg', 'image9.jpg',
    'image10.jpg', 'image11.jpg', 'image12.jpg',
    'image13.jpg', 'image14.jpg', 'image15.jpg'
    , 'image16.jpg', 'image17.jpg'
  ];

  return (
    <div>
    <Header/>

    <div >
    <section class="background-section" style={{backgroundImage:'url(/assests/images/aboutus.jpeg)',height:"400px",width:"100%"}} >
    <div class="content">
    <h1 style={{marginTop:"180PX",color:"orange" , lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "28px",
                fontWeight: "400"}}><b>About Us</b></h1><br/>
        <h4 style={{fontFamily:"serif",fontSize:"20px",color:"whitesmoke"}}>
</h4>
</div>
</section>
      <section className="bottom-section"  style={{backgroundImage:'url(/assests/images/aboutusbg.jpg)'}}>
        <div className="success-section">
        


          <h2 style={{  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "22px",
                fontWeight: "400",color:"blue" ,color:"blue"}}>
          Mr. V.Purushotham Reddy (Managing Partner)</h2>
<p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "justify"}}>An Engineering Graduate, having more than 15 years vast experience in the field of electrical infrastructure. Having experience in erection and commissioning of all types of distribution system line works, High Voltage Distribution System works. He looks after the raw material procurement, material management and execution of various projects. His work knowledge, commitment and dedication guides the company in proper way in diversifying and expanding their business. He maintain good relations with the esteemed customers and fulfilling their business needs.
</p>
        </div>
        
        {/* <div className="profile-section">
          <div className="founder-profile">
            <div className="ceo-image">
              <h2 style={{fontFamily:"serif",fontSize:"22px"}}><br/></h2>
             
              <img src="download.jpeg" alt="CEO Image" />
            </div>
          </div>

          
      
          
        </div> */}
      </section>
      
      <section className="bottom-section" style={{backgroundImage:'url(/assests/images/aboutusbg.jpg)'}}>
        <div className="success-section">
            
        

        <h3 style={{        
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "22px",
                fontWeight: "400",color:"blue"}}> Mrs. V. Vani (Partner)
 <br/></h3>
       
 <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "justify"}}>She maintaining day to day activities at fabrication unit and the production. She takes care of despatches of the materials in time.

</p>
      </div>
      </section>
      
      <div className="our-works" style={{textAlign:"center"}} >
            <h2 style={{fontFamily:"serif"}}><b>GALLERY</b></h2>
          </div>
          <div className="gallery" style={{backgroundImage:'url(/assests/images/galbg.jpg)'}}>
            <div className="gallery-grid">
              {galleryImages.map((image, index) => (
                <div key={index} className="gallery-item1">
                  <img src={`/${index + 1}.jpg`} alt={`Gallery item ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
    </div>
    <br/>
    <section class="client_section " style={{backgroundColor:"dimgray"}} >
    {/* <div class="container">
        <h2 style={{color:"cornsilk",textAlign:"center",fontFamily:"serif"}}>
          <br/>
           OUR CLIENTS 
           <br/>
          <br/>
        </h2>
      </div>
<div>
</div> */}
<div>

<CarouselAutoPlay/>

</div>
</section>


    <Footer/>
    </div>
  );
};

export default AboutUs;
