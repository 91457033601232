import React, { useState } from 'react';
import './AboutUs.css'; // Import your CSS file

const Galleryimages = () => {
  const [isHovered, setIsHovered] = useState(false);
  const images = [
    '1.jpg', '2.jpg', '4.jpg','3.jpg','6.jpg'
  ];

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div >
      <h2 style={{
                paddingTop: "0px",
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "26px",
                fontWeight: "400",
                color: "darkorange", color:"darkblue" ,textAlign:"center", boxSizing: 'border-box'}}><b>GALLERY</b></h2>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div 
            key={index} 
            className="gallery-item"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <a href={image} target="_blank" rel="noopener noreferrer">
              <img 
                src={image} 
                alt={`Gallery Image ${index + 1}`}
                className={isHovered ? 'gallery-image hover' : 'gallery-image'}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Galleryimages;