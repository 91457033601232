import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import CarouselAutoPlay from './CarouselAutoPlay';
import Headers from './Header';
import Footer from './Footer';
import './AboutUs.css'; 
import Galleryimages from './Galleryimages';
import './Carousel.css';
import ImageGallery from './ImageGallery';

class DistributedProduct extends React.Component{
   componentDidMount() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            observer.unobserve(entry.target); // Optional: unobserve after first trigger
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    const slideInElements = document.querySelectorAll('.slide-in-text');

    slideInElements.forEach((element) => {
      observer.observe(element);
    });
  }
    render(){
        return(
          <div class="container-xxl p-0"  >
  <Headers/>
  <section class="background-section" style={{backgroundImage:'url(/assests/images/prof1.jpg)',height:"700px",width:"100%"}} >
    <div class="content">
      
    <h2 style={{
                paddingTop: "0px",
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "26px",
                fontWeight: "400",
               color:"orange"}}><b>Our Products</b></h2><br/>
        <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',color:"whitesmoke"}}>We manufacture and supply various types of electrical overhead hardware fittings. Available in all standard sizes, grades, shapes and specifications meet with various DISCOM Standards. Our products are praised for their durability, anti-corrosive nature, dimensional accuracy and precision engineering.</p>

</div>
</section>
  


<section style={{marginTop:"50px" ,background:"white"}}>
    
<div class="container">
<div className="slide-in-text"  >
      <div class="row">
    
        <div class="col-lg-4 col-md-4" >
        
      
  
  <ul style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.6', /* Spacing between lines */
  letterSpacing: '0.3px'}}>
  <li> For 33kV Line</li>
  <li>V-cross arms
</li>
  <li>Complete DP Sets
</li>
  




<li>Box type cross arms</li>
<li>Stay set with suitable back clamps all type of poles</li>

  </ul>
  
  
</div>
<div class="col-lg-4 col-md-4">

<ul style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.6', /* Spacing between lines */
  letterSpacing: '0.3px'}}>
  
  <li>For 11kV Line</li>
  <li>V-cross arms</li>
  <li>Complete DP Sets</li>
  <li>Box type cross arms</li>
  <li>Stay set with suitable back clamps for 8mtr & 9mtr poles</li>
  </ul>
 </div> 


       <div class="col-lg-4 col-md-4">

       <ul style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "17px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.6', /* Spacing between lines */
  letterSpacing: '0.3px'}}>

       <li>For 3phase LT Line</li>
<li>5w cross arms</li>
<li>4w cross arms</li>
<li>2w cross arms</li>
<li>Cantilever type cross arms with suitable clamps all poles</li>
<li>IIIA & IIIB type complete DP structure set.</li>
<li>LT 'A' type Distribution Boxes with accessories.</li>
</ul>
       </div>
       </div>
       <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "20px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.6', /* Spacing between lines */
  letterSpacing: '0.3px',color:"blue"}}>
       All types of struts and additional fabrication designs required for specific area distribution line materials supply.
       Our fabricated materials are widely supplied in various Government & Private entities for APSPDCL, TSSPDCL, TSNPDCL, APEPDCL and also various Electrical Infra companies.</p>
       </div></div>
</section>

<ImageGallery/>


<section style={{marginTop:"80px",fontFamily:"serif",fontSize:"20px"}}>
    
<div class="container">
<h5 style={{
                paddingTop: "0px",
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "26px",
                fontWeight: "400",
               color:"orange"}}>
             
             <b>Quality Assurance</b> </h5><br/>
      <div class="row">
      
      <div class="col-lg-5 col-md-6">

<Image src="assests/images/QA_Picture1.png" style={{height:"220px",width:"70%"}}/>

 </div>
        <div class="col-lg-7 col-md-6" >
        <div class="detail-box">
           
              </div>
             <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.6', /* Spacing between lines */
  letterSpacing: '0.3px'
  ,textAlign:"justify"}}> 
Providing optimum grade products to the valuable patrons has been of significant importance for us, from commencement. Due to this all our products are fabricated from excellent quality material, sourced from genuine and reliable vendors of the market. Besides, we source our some products including steel and hardware items that are procured from certified / approved vendors, who are known for their qualitative range. A quality checking team is appointed by us for examining self developed as well as procured products and ensuring their flawlessness, before the end of delivery.</p></div>
      
       </div>
       </div>
</section>


<section style={{marginTop:"80px",fontFamily:"serif",fontSize:"20px"}}>
    
<div class="container">

      <div class="row">
      
     
        <div class="col-lg-7 col-md-6" >
        <div class="detail-box">
           
              </div>
	
              <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  lineHeight: '1.6', /* Spacing between lines */
  letterSpacing: '0.3px'
  ,textAlign:"justify"}}>   In order to satisfy the diverse preferences of customers, we are offering superior quality fabricating material viz. v-cross arms, etc., is manufactured by utilizing quality assured raw materials by our team of well versed professionals with the help of modern technology. The cross arms are widely used in many electrical and telephone transmission lines. Our prestigious patrons can avail this range from us in various sizes at negotiable rates.
  </p></div>
<div class="col-lg-5 col-md-6">

<Image src="assests/images/QA_Picture2.png" style={{height:"280px",width:"70%"}}/>

 </div>
       </div>
       </div>
</section>



        <section class="client_section " style={{backgroundColor:"dimgray"}} >
    {/* <div class="container">
        <h2 style={{color:"cornsilk",textAlign:"center",fontFamily:"serif"}}>
          <br/>
           OUR CLIENTS 
           <br/>
          <br/>
        </h2>
      </div>
<div>
</div> */}
<div>

<CarouselAutoPlay/>

</div>
</section>


  
  <div style={{backgroundColor:"gray"}}>

  <Footer/>
  </div>
 </div>
        
        );}
}

export default DistributedProduct;


