import React, { Component, createRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CarouselAutoPlay from './CarouselAutoPlay';
import Headers from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import ImageGallery from './ImageGallery';
import ScrollCounter from './ScrollCounter';
import Galleryimages from './Galleryimages';
import CarouselComponent from './CarouselComponent';
import './Carousel.css'; 
import ProjectAccordian from './ProjectAccordian';
class Home extends React.Component{

  // componentDidMount() {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           entry.target.classList.add('slide-in');
  //           observer.unobserve(entry.target); // Optional: unobserve after first trigger
  //         }
  //       });
  //     },
  //     { threshold: 0.1 } // Trigger when 10% of the element is visible
  //   );

  //   const slideInElements = document.querySelectorAll('.slide-in-text');

  //   slideInElements.forEach((element) => {
  //     observer.observe(element);
  //   });
  // }

  
  constructor(props) {
    super(props);
    this.slideInElementsRef = createRef(); // Ref for slide-in elements
    this.counterSectionRef = createRef(); // Ref for the section containing counters

  }

  componentDidMount() {
    // Observer for slide-in text elements
    const slideInObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('Element is intersecting:', entry.target); // Debugging log
            entry.target.classList.add('show'); // Add 'show' class
            slideInObserver.unobserve(entry.target); // Optional: unobserve after first trigger
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    // Observe slide-in text elements
    if (this.slideInElementsRef.current) {
      // const slideInElements = this.slideInElementsRef.current.querySelectorAll('.slide-in-text');
      // slideInElements.forEach((element) => {
      //   console.log('Observing:', element); // Check elements being observed
      //   slideInObserver.observe(element);
      // });


      const slideInElements = document.querySelectorAll('.slide-in-text');

      slideInElements.forEach((element) => {
        slideInObserver.observe(element);
      });
      
    }
  

    // Observer for counters
    const counterObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Optional: Trigger any animation or class changes here if needed
            entry.target.classList.add('show');
            counterObserver.unobserve(entry.target); // Optional: unobserve after first trigger
          }
        });
      },
      { threshold: window.innerWidth < 768 ? 0.05 : 0.1, } // Adjust the threshold as needed
    );

    // Observe the section containing counters
    if (this.counterSectionRef.current) {
      counterObserver.observe(this.counterSectionRef.current);
    }
  }
    render(){
        return(
          <div>

<div class="container-fluid p-0" style={{backgroundColor:"whitesmoke"}} >
<Headers/>

<section >
  <CarouselComponent/>
{/* <div className="image-container">
      <img src='assests/images/homebg.jpg' style={{height:"700px",width:"100%"}} alt="Zooming" />
    </div> */}
</section>

<section style={{ backgroundColor: "#0a0f43" }}>
  <div className="container">
    <div className="row" style={{ backgroundColor: "#0a0f43",paddingTop:"50px" }}>
      <div className="col-lg-5 col-md-6">
        <div className="slide-in-text" ref={this.slideInElementsRef}>
          <div className="detail-box">
            <h2
              style={{
                paddingTop: "0px",
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "24px",
                fontWeight: "400",
                color: "darkorange"

              }}
            >
              <b>WE PROVIDE</b>
            </h2>
            <br />
            <p
              style={{
                // fontFamily: "serif",
                // fontSize: "20px",
                // color: "wheat",
                
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "300", /* Light font weight */
  color: "wheat" ,/* Light gray or dark gray */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "justify"
              }}
            >
              We manufacture and supply various types of electrical overhead
              hardware fittings. Available in all standard sizes, grades,
              shapes, and specifications meet with various DISCOM Standards. Our
              products are praised for their durability, anti-corrosive nature,
              dimensional accuracy, and precision engineering. Our fabricated
              materials are widely supplied in various Government & Private
              entities for APSPDCL, TSSPDCL, TSNPDCL, APEPDCL and also various
              Electrical Infra companies.
            </p>
            {/* <a href="">
              Read More
            </a> */}
          </div>
        </div>
      </div>

      <div className="col-lg-7 col-md-6">
        <div className="img-box" style={{ height: "100%", width: "100%" }}>
          <Carousel>
            <Carousel.Item>
              <img
                src="1.JPG"
                style={{
                  objectFit: "cover",
                  height: "auto",
                  maxHeight: "350px", // Adjust this value as needed
                  width: "100%",
                }}
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="2.JPG"
                style={{
                  objectFit: "cover",
                  height: "auto",
                  maxHeight: "350px", // Adjust this value as needed
                  width: "100%",
                }}
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="3.JPG"
                style={{
                  objectFit: "cover",
                  height: "auto",
                  maxHeight: "350px", // Adjust this value as needed
                  width: "100%",
                }}
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="4.JPG"
                style={{
                  objectFit: "cover",
                  height: "auto",
                  maxHeight: "350px", // Adjust this value as needed
                  width: "100%",
                }}
                alt=""
              />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img src="assests/images/slide3.JPEG" alt=""/>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</section>

  <section class="professional_section layout_padding" style={{backgroundColor:"whitesmoke"}}>
    <div class="container">
      
          <div class="detail-box">
          <div className="slide-in-text" ref={this.slideInElementsRef}>

            <h2 style={{
                paddingTop: "0px",
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "24px",
                fontWeight: "400"}}>
             <b>WHO WE ARE</b>
            </h2>
            <p style={{  fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.7px',
  textAlign: "justify"}}>
We, sri shiva parvathi projects & constructions, a partnership firm advent in the year 2015 with an objective to provide services in the area of electrical infrastructure. Founded by mr. V. Purushotham Reddy, established state-of-the-art fabrication unit at nellore, andhra pradesh, india engaged in manufacturing and supply of requisite electrical materials viz. , overhead fabrication material for 33kv, 11kv distribution lines and transmission lines to various government entities like apspdcl, tsspdcl, apepdcl and various private sector entities. Registered with ministry of micro, small and medium enterprises of government of india.              <br/>        {<a href="/pages">
              Read More
            </a> }
            </p>
          </div>
          </div>

        </div>
  </section>
  <section class="feature_section" style={{backgroundColor:"whitesmoke"}}>
    <div class="container">
      <div class="feature_container" >
        <div class="box">
          <div class="img-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
</svg>
          </div>
          <h5 class="name">
            VISION
          </h5>
          <br/>
<p style={{ fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "15px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  /* Spacing between lines */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px'
  ,textAlign:"justify"}}>        
  SSPPC aspires to be a strong and quality conscious company in the areas of manufacturing, supply and erection of electrical transmission towers, distribution lines, substations through engineering, procurement and construction. Be recognized globally as a leading, all round supplier and infrastructure provider through excellence in people, planning and processes. 
</p> 
</div>      
 <div class="box active">
          <div class="img-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gem" viewBox="0 0 16 16">
  <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z"/>
</svg>
          </div>
          <h5 class="name">
            VALUES
            
          </h5>
          <br/>
          <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "15px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  /* Spacing between lines */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px'
  ,textAlign:"justify"}}>  
          Commitment to deliver products/services to customers in the best quality and right quantity.Exceed customer satisfaction in terms of quality, price, on time delivery and support.Develop long term relationships with the vendors / business partners to support our goals.Commitment to sustainability and to act in an environmentally friendly way.</p>
        </div>
        <div class="box">
          <div class="img-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
  <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
</svg>
          </div>
          <h5 class="name">
            MISSION
          </h5>
          <br/>
          <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "15px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  /* Spacing between lines */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px'
  ,textAlign:"justify"}}> 

Our Mission is gain utmost satisfaction of our clients, and be a preferred partner for their most challenging projects. to encourage innovation, teamwork, professional integrity, commitment, up gradation of knowledge and skills of employees and a safe working environment. and to enhance and maintain our potential in providing our services by ever expanding in different infrastructure segment
     </p>  </div></div>
    </div>
  </section>

  <section class="background-section-project layout_padding " style={{backgroundImage:'url(/assests/images/prof2.jpg)'}}>
  
<div class="content-project" >
  <h2 style={{
                paddingTop: "0px",
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "28px",
                fontWeight: "400",
                color: "darkorange"}}><b>Our Philosophy of Service</b></h2><br/>
<p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.7px',
  textAlign: "justify",color:"wheat"}}>Our philosophy is simple – to continue to succeed through our total commitment to engineering excellence and highest standards in delivering quality products & customized services. Creativity and innovation are cornerstones of our approach, as is the integration of advanced technology with responsive management. We apply a rigorous quality assurance system to all phases of each project and attach particular importance to the establishment of a close working relationship with our clientele. Project execution teams are dedicated to providing high quality professional service. They are pro–active in our look, seeking to identify and act on opportunities as they arise and address problems before they occur.</p>
</div>

</section>
  





 
  <section class="professional_section layout_padding" style={{backgroundImage:'url(/assests/images/galbg.jpg)'}}>
  <div class="container">
      
          <div class="detail-box">
          <div className="slide-in-text" ref={this.slideInElementsRef}>
  <br/>

      <h2 style={{
                paddingTop: "0px",
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "28px",
                fontWeight: "400"}}><b>Projects Executed</b></h2>
      <br/>
      <ProjectAccordian/>

      
      </div>
</div>
</div>
</section>
  
    <section
            ref={this.counterSectionRef}
           
       class="background-section-project" style={{backgroundImage:'url(/assests/images/prof4.JPG)',height:"400px"}}>
        <div class="container-xxl  fact py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className='content-project'>
                  <div class="content py-5 px-lg-5">
                      <div class="row g-4">
                          <div class="col-md-4 col-lg-4 text-center wow fadeIn" data-wow-delay="0.1s">
                              <i class="fa fa-certificate fa-3x text-secondary mb-3"></i>
                              <ScrollCounter targetNumber={15} delay={0.7}  style={{ color: 'darkorange' }}/>
                              <p class="text-white mb-0"  style={{textAlign:"center"}}>Years Experience</p>
                          </div>
                          {/* <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
                          <i class="fa fa-users fa-3x text-secondary mb-3"></i>
                          <h1 class="text-white mb-2" data-toggle="counter-up">50</h1>
                              <p class="text-white mb-0">Team Members</p>
                          </div> */}
                          <div class="col-md-4 col-lg-4 text-center wow fadeIn" data-wow-delay="0.5s">
                              <i class="fa fa-users fa-3x text-secondary mb-3"></i>
                              <ScrollCounter  targetNumber={15} delay={0.1} />
                              <p class="text-white mb-0"  style={{textAlign:"center"}}>Satisfied Clients</p>
                          </div>
                          <div class="col-md-4 col-lg-4 text-center wow fadeIn" data-wow-delay="0.7s">
                              <i class="fa fa-check fa-3x text-secondary mb-3"></i>
                              <ScrollCounter targetNumber={100} delay={0.7}  style={{ color: 'darkorange' }}/>
                              <p class="text-white mb-0"  style={{textAlign:"center"}}>Completed Products</p>
                          </div>
                      </div>
                      </div>
                  </div>
              </div>
              </section>


        <section class="client_section " style={{backgroundColor:"black"}} >
  
<div>

<CarouselAutoPlay/>

</div>
</section>


  <section class="background-section-gallery" style={{backgroundImage:'url(/assests/images/galbg.jpg)'}}>
  <div className='content-gallery'>
    {/* <h2 style={{fontFamily:"serif"}}><b>GALLERY</b></h2>
    <br/> */}
    <Galleryimages/>
<p style={{textAlign:"center"}}> 
{<a href="/aboutus" style={{textDecoration:"none", boxSizing: 'border-box',fontSize:"16px"}}>
              View More
            </a> }
            </p>
    </div></section>
  
<Footer/>
  </div>


</div>

);
    }
}

export default Home;

