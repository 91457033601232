import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Carousel } from 'bootstrap';
import './Carousel.css';

const CarouselComponent = () => {
  useEffect(() => {
    const carouselElement = document.querySelector('#carouselExampleInterval');
    
    if (carouselElement && !carouselElement.dataset.carouselInitialized) {
      new Carousel(carouselElement, {
        interval: 3000, // Set a consistent interval of 3 seconds for all slides
        wrap: true,     // Enable looping of the slides
        pause: false,   // Disable pausing on hover
      });
      // Mark this carousel as initialized
      carouselElement.dataset.carouselInitialized = true;
    }
  }, []);

  return (
    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="/assests/images/prof4.jpg" className="d-block w-100" alt="Wild Landscape" />
        </div>
        <div className="carousel-item">
          <img src="/assests/images/prof1.jpg" className="d-block w-100" alt="Camera" />
        </div>
        <div className="carousel-item">
          <img src="/assests/images/prof2.jpg" className="d-block w-100" alt="Exotic Fruits" />
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default CarouselComponent;
