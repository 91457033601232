// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes zoom {
    from {
      transform: scale(1, 1);
    }
    to {
      transform: scale(1.5, 1.5);
    }
  }
  
  .carouselinner .item > img {
    animation: zoom 20s;
  }`, "",{"version":3,"sources":["webpack://./src/pages/HomeCarousel.css"],"names":[],"mappings":"AASE;IACE;MACE,sBAAsB;IACxB;IACA;MACE,0BAA0B;IAC5B;EACF;;EAEA;IAEE,mBAAmB;EACrB","sourcesContent":["@-webkit-keyframes zoom {\r\n    from {\r\n      -webkit-transform: scale(1, 1);\r\n    }\r\n    to {\r\n      -webkit-transform: scale(1.5, 1.5);\r\n    }\r\n  }\r\n  \r\n  @keyframes zoom {\r\n    from {\r\n      transform: scale(1, 1);\r\n    }\r\n    to {\r\n      transform: scale(1.5, 1.5);\r\n    }\r\n  }\r\n  \r\n  .carouselinner .item > img {\r\n    -webkit-animation: zoom 20s;\r\n    animation: zoom 20s;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
