import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from './pages/Home';
import Contact from './pages/contact';
import Client from './pages/Client';
import ProductDistributed from './pages/DistributedProduct';
import AboutUs from './pages/aboutus';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

function App() {
  return (
    
<Router>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Home />}
                    />
 
                    <Route
                        path="/contactUs"
                        element={<Contact />}
                    />
 
                    <Route
                        path="/client"
                        element={<Client />}
                    />
  <Route
                        path="/aboutus"
                        element={<AboutUs />}
                    />
                    <Route
                        path="/pages"
                        element={<ProductDistributed />}
                    />
 
                    <Route
                        path="*"
                        element={<Navigate to="/" />}
                    />
                    
                </Routes>
            </Router>  );
}

export default App;
