import React from 'react';
import { Col } from 'react-bootstrap';

class Footer extends React.Component {
  render() {
    return (
      <div>
        <section className="contact_section layout_padding" style={{ backgroundColor: "black" }}>
          <div className="container">
            <div className="row" style={{ fontFamily: "serif" }}>
              <div className="col-md-6">
                <h4 style={{ color: "#be4a06"  ,boxSizing: 'border-box'}}><b>CONNECT WITH US</b></h4>
                <div className="row">
                  <div className="col-md-4">
                    <i className="fa fa-map-marker" aria-hidden="true" style={{color:"#be4a06" }}></i>
                    <p style={{ fontFamily: "serif", fontSize: "18px", color: "white",    boxSizing: 'border-box'
 }}>
                      Regd. Office: Flat No.401, Sai Ratnam Residency, Santhi Nagar, Nellore-524004, Andhra Pradesh, India
                    </p>
                  </div>
                  <div className="col-md-4">
                    <i className="fa fa-envelope" aria-hidden="true" style={{color:"#be4a06" }}></i>
                    <p style= {{ fontFamily: "serif", fontSize: "18px", color: "white",    boxSizing: 'border-box'}}>
                      shivaparvathiprojects<br/>.constr@gmail.com
                    </p>
                  </div>
                  <div className="col-md-4">
                    <i className="fa fa-phone" aria-hidden="true" style={{color:"#be4a06" }}></i>
                    <p style={{ fontFamily: "serif", fontSize: "16px", color: "white" }}>
                      +919480624111
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="map" style={{ textAlign: "left", marginTop: '20px' }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d1984829.1314009288!2d77.49413845440381!3d13.68089204712296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d12.944793299999999!2d77.7113593!4m5!1s0x3a4cf3051100e4f7%3A0x7d2f03b20dc75362!2sCXF5%2BX3W%20Sai%20Ratnam%20Residency%2C%20Podalakur%20Rd%2C%20Andhra%20Pradesh!3m2!1d14.424999099999999!2d79.9577085!5e0!3m2!1sen!2sin!4v1711634563630!5m2!1sen!2sin"
                    style={{
                      border: 0,
                      width: "100%",
                      height: "200px", // adjust the height as needed
                      maxWidth: "100%",
                      maxHeight: "300px", // adjust the height as needed
                    }}
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '20px' ,fontSize:"18px"}}>
              <div className="col-md-6">
                <h4 style={{ fontFamily: "serif", color: "#be4a06" , boxSizing: 'border-box'}}><b>QUICK LINKS</b></h4>
                <div style={{ fontFamily: "serif", padding: "3px" , boxSizing: 'border-box'}}>
                  <a href="index.html" style={{ color: "white", padding: '5px', textDecoration: 'none' }}>Home</a>
                  <a href="/aboutus" style={{ color: "white", padding: '14px', textDecoration: 'none' }}>About Us</a>
                  <a href="/client" style={{ color: "white", padding: '14px', textDecoration: 'none' }}>Our Clients</a>
                  <a href="/pages" style={{ color: "white", padding: '14px', textDecoration: 'none' }}>Products</a>
                  <a href="/contactUs" style={{ color: "white", padding: '14px', textDecoration: 'none' }}>Contact Us</a>
                </div>
              </div>

              <div className="col-md-6">
                <p style={{ paddingTop: "0px",
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "16px",
                fontWeight: "400", textAlign:"justify",color: "#be4a06", marginTop: '20px', boxSizing: 'border-box' }}>
                  In the Electrical Infrastructure, we have diversified our activities by undertaking execution of various Projects from reputed companies in Electrical Infrastructure and contributed our part in development of Country.
                </p>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer_section" style={{ backgroundColor: '#be4a06', fontFamily: "serif" }}>
          <div className="container">
            <p style={{ color: "whitesmoke" }}>
            © 2024 All Rights Reserved By Hanumaa consultancy
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
